import React, { useEffect, useState } from "react";
import { Api } from "../../api/apiCotacoes";
import { useParams } from "react-router-dom";
import { Visualizar } from "./utils/visualizar";
import { VisualizarDetalhe } from "./utils/visualizarDetalhes";
import { VisualizarSelecionado } from "./utils/visualizarSelecionado";
import Icone from "../../assets/icones/icone.png"
import { VisualizarSelecionadoTodos } from "./utils/visualizarSelecionadoTodos";
import { useCookies } from 'react-cookie';

function CotacoesPrecos() {
  document.title = "Corretor Parceiro";
  const [ramo, setRamo] = useState("");
  useEffect(() => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("saude")) {
      setRamo("2");
    } else if (url.includes("odontologico")) {
      setRamo("1");
    }
  }, []);


  const [dados, setDados] = useState();
  const [modalFiltro, setModalFiltro] = useState(false);
  const [info, setInfo] = useState();
  const [modalVisualizarDetalhe, setModalVisualizarDetalhe] = useState(false);
  const [profissoes, setProfissoes] = useState();
  const [profissoesSelect, setProfissoesSelect] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [modalExcluir, setModalExcluir] = useState(false);
  const [modalVisualizarOperadora, setModalVisualizarOperadora] =
    useState(false);
  const [buscar, setBuscar] = useState("");
  const [list, setList] = useState([]);
  const [cookie, setCookie] = useCookies(['authUser'])
  const obj = cookie.authUser


  const [faixas, setFaixas] = useState([]);
  const [calculo, setCalculo] = useState([]);
  const [calculoDetalhe, setCalculoDetalhe] = useState([]);
  const [infoDependente, setInfoDependente] = useState([]);
  const [
    modalVisualizarDetalheSelecionado,
    setModalVisualizarDetalheSelecionado
  ] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);

  const [modalVisualizarDetalheSelecionadoTotal, setModalVisualizarDetalheSelecionadoTotal] = useState(false);
  const [selectedsTotal, setSelectedsTotal] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [filtro1, setFiltro1] = useState("");
  const [filtro2, setFiltro2] = useState("");
  const [filtro3, setFiltro3] = useState("");
  const [filtro4, setFiltro4] = useState("");
  const [filtro5, setFiltro5] = useState("");
  const [filtro6, setFiltro6] = useState("");
  const [loadingDetalheSelecionado, setLoadingDetalheSelecionado] = useState(false)
  const [calculoFiltro, setCalculoFiltro] = useState([])
  const [carregamento, setCarregamento] = useState(false)

  const [visualizar, setVisualizar] = useState([])
  useEffect(() => {
    Listagem();

    handleProfissoes();
    handleFaixaValue();
    handleMunicipios();
  }, []);
  const handleMunicipios = async () => {
    let option = await Api.municipio();
    setMunicipios(option.option);
  };
  const handleCarregamento = (status) => {
    setCarregamento(status)
    let body = document.querySelector("body");
    if (status) {

      if (body) {

        body.style.cursor = "wait";
      }
    } else {
      if (body) {

        body.style.cursor = "";
      }
    }
  };


  const handleProfissoes = async () => {
    let dados = await Api.profissoes();
    let val = dados.option;
    val?.unshift({ nome: "Sem profissão", id: "" });
    setProfissoes(val);
    setProfissoesSelect([]);
  };


  const handleFaixaValue = async () => {
    let list = await Api.faixas({ id: 8 });
    if (list) {
      let listFaixas = list
      listFaixas = listFaixas?.filter((item, index) => index != 0);
      setFaixas(listFaixas);
    }
  };

  useEffect(() => {
    handleCalculo(id)
  }, [id])
  const [loadingDetalhes, setLoadingDetalhe] = useState(false)
  const handleOperadora = async (item, status = true) => {
    // setModalVisualizarDetalhe(status);
    setLoadingDetalhe(true);
    setLoading(true)

    item.tabelas?.sort((a, b) =>
      a.tabelafaixas.length > 0 ?
        Number(a?.tabelafaixas?.sort((a1, b1) => Number(a1?.valor?.replace('.', '')?.replace(',', '.')) - Number(b1?.valor?.replace('.', '')?.replace(',', '.'))).reduce((menor, item) => {
          return item.valor < menor.valor ? item : menor;
        })?.valor?.replace(',', '.')?.replace(',', '.'))

        - Number(b?.tabelafaixas?.sort((a1, b1) => Number(a1?.valor?.replace('.', '')?.replace(',', '.')) - Number(b1?.valor?.replace('.', '')?.replace(',', '.'))).reduce((menor, item) => {
          return item.valor < menor.valor ? item : menor;
        })?.valor?.replace(',', '.')?.replace(',', '.'))
        : Number(a.precovida?.replace(',', '.')?.replace(',', '.')) - Number(b.precovida?.replace(',', '.')?.replace(',', '.'))
    )

    await gerarTabelaSelecionada([item], dados?.array, status);
    setLoadingDetalhe(false);
    setLoading(false)
    return true
  };


  const gerarTabelaSelecionada = (dados, status = false) => {

    let array = []

    dados?.filter((fil) => fil.status == 1)?.map((tabela) => {
      array.push({
        visualizar: true, id: tabela.id, operadora: tabela.nome, imagem: tabela.imagem,
        tabelas: {
          nome: "",
          id: calculoDetalhe?.id,
          estado: calculoDetalhe?.estado,
          produto: [],
          faixas: [],
          observacoes: [],
          areacomercializacao: [],
          dependente: [],
          documento: [],
          pagamento: [],
          rede: [],
          reembolso: [],
          carencia: [],
          entidades: [],
          coparticipacoes: []
        }
      })
      tabela.tabelas?.map((item) => {
        let indexOperado = array.findIndex((arrayItem) => arrayItem.id == tabela.id)

        if (status ? status : selecteds.some((item3) => item3.idtabela === item.id)) {
          array[indexOperado].tabelas.produto.push({
            id: item.id, nome: item.nome, cobertura: item.cobertura, acomodacao: item.acomodacao, coparticipacao: item.coparticipacao, possuereembolso: item.possuereembolso,
            percentual: item.percentual, vidas: `${item.minvidas} a ${item.maxvidas}`, faixas: item.tabelafaixas.sort((pessoa1, pessoa2) => pessoa1.id - pessoa2.id),
            precovida: item.precovida, item, status: item.status
          });
          if (item.coparticipaccao?.id) {


            if (item.coparticipaccao?.id && !array[indexOperado].tabelas.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id)) {
              array[indexOperado].tabelas.coparticipacoes.push({ id: item.coparticipaccao.id, item: item.coparticipaccao, produto: [item.nome] })
            } else {
              array[indexOperado].tabelas.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id ? arr.produto.push(item.nome) : '')
            }
          }
          if (item.observacao?.id && !array[indexOperado].tabelas.observacoes.some((arr) => arr.id == item.observacao.id)) {
            array[indexOperado].tabelas.observacoes.push({ id: item.observacao?.id, item: item.observacao, produto: [item.nome] });
          } else {
            array[indexOperado].tabelas.observacoes.some((arr) =>
              arr.id == item.observacao?.id ? arr.produto.push(item.nome) : ""
            );
          }



          if (item.areacomercializacao?.id && !array[indexOperado].tabelas.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id)) {
            array[indexOperado].tabelas.areacomercializacao.push({
              id: item.areacomercializacao.id, descricoes: item.areacomercializacao?.areacomercializacaodescricaos, produto: [item.nome], municipios: item.areacomercializacaoestadosmunicipios
            });
          } else {
            array[indexOperado].tabelas.areacomercializacao.some((arr) =>
              arr.id == item.areacomercializacao?.id
                ? arr.produto.push(item.nome)
                : ""
            );
          }
          if (
            item.dependente?.id &&
            !array[indexOperado].tabelas.dependente.some((arr) => arr.id == item.dependente?.id)
          ) {
            array[indexOperado].tabelas.dependente.push({
              id: item.dependente?.id,
              item: item.dependente,
              produto: [item?.nome]
            });
          } else {
            array[indexOperado].tabelas.dependente.some((arr) =>
              arr.id == item.dependente?.id ? arr.produto.push(item.nome) : ""
            );
          }
          if (
            item.documento?.id &&
            !array[indexOperado].tabelas.documento.some((arr) => arr.id == item.documento?.id)
          ) {
            array[indexOperado].tabelas.documento.push({
              id: item.documento.id,
              item: item.documento,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.documento.some((arr) =>
              arr.id == item.documento?.id ? arr.produto.push(item.nome) : ""
            );
          }

          if (
            item.pagamento?.id &&
            !array[indexOperado].tabelas.pagamento.some((arr) => arr.id == item.pagamento?.id)
          ) {
            array[indexOperado].tabelas.pagamento.push({
              id: item.pagamento.id,
              item: item.pagamento,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.pagamento.some((arr) =>
              arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : ""
            );
          }

          if (
            item.carencia?.id &&
            !array[indexOperado].tabelas.carencia.some((arr) => arr.id == item.carencia?.id)
          ) {
            array[indexOperado].tabelas.carencia.push({
              id: item.carencia.id,
              item: item.carencia,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.carencia.some((arr) =>
              arr.id == item.carencia?.id ? arr.produto.push(item.nome) : ""
            );
          }



          item.tabelafaixas.map((item2) => {
            if (item2.rede?.id && !array[indexOperado].tabelas.rede.some((arr) => arr.id == item2.rede?.id)) {
              array[indexOperado].tabelas.rede.push({ id: item2.rede.id, item: item2.rede, produto: [item.nome] });
            } else {
              array[indexOperado].tabelas.rede.some((arr) => arr.id == item2.rede?.id ? (!arr.produto.some((prod) => prod == item.nome) ? arr.produto.push(item.nome) : '') : "");
            }
          })
          if (
            item.reembolso?.id &&
            !array[indexOperado].tabelas.reembolso.some((arr) => arr.id == item.reembolso?.id)
          ) {
            array[indexOperado].tabelas.reembolso.push({ id: item.reembolso.id, item: item.reembolso, produto: [item.nome] });
          } else {
            array[indexOperado].tabelas.reembolso.some((arr) =>
              arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : ""
            );
          }
          item?.grupoentidade?.map((item2) => {
            if (item2?.identidade && !array[indexOperado]?.tabelas?.entidades?.some((arr) => arr.id == item2.identidade)) {
              array[indexOperado]?.tabelas?.entidades?.push({
                id: item2.identidade
                , item: item2, produto: [item.nome]
              });
            } else {
              array[indexOperado]?.tabelas?.entidades?.some((arr) => arr.id == item2.identidade ? (!arr.produto.some((prod) => prod == item.nome) ? arr.produto.push(item.nome) : '') : "");
            }
          })
        }
      })

    });
    setInfo(array);
    if (status) {
      setModalVisualizarDetalheSelecionadoTotal(true)
    } else {
      setModalVisualizarDetalheSelecionado(true);
    }

    setLoadingDetalheSelecionado(false);
  };
  const Listagem = async () => {
    handleCarregamento(true);
    let dados = await Api.listagem();
    setList(dados.list);
    handleCarregamento(false);
  };


  useEffect(() => {
    setCalculoFiltro(calculo)
    setVisualizar(calculo.map(() => { return true }))
  }, [calculo])
  const handleCalculo = async (id) => {
    setLoading(true)
    handleCarregamento(true);
    let dados = await Api.calculo({ id: id, filtro1, filtro2, filtro3, filtro4, filtro5, filtro6 });
    let arrayValorOperadora = []
    if (dados?.listagem?.length > 0) {
      dados.listagem?.map((item) => {
        item.tabelas.map((item2) => {
          item2?.tabelafaixas.filter((fill) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).map((item3) => {
            if (item3.valor && Number(item3.valor?.replace('.', '').replace(',', '.')) && !arrayValorOperadora.some((filtro) => filtro.valor == Number(item3.valor?.replace('.', '')?.replace(',', '.')) && filtro.operadora == item.nome)) {
              arrayValorOperadora.push({ valor: Number(item3.valor?.replace('.', '')?.replace(',', '.')), operadora: item.nome })
            }
          })
        })
      })
      arrayValorOperadora.sort((a, b) => a.valor - b.valor)
      dados.listagem = dados?.listagem
        .filter((item) =>
          item.tabelas.some((item2) =>
            item2.tabelafaixas.some((item3) => item3.valor))).map((item) => ({
              ...item,
              tabelas: item.tabelas.map((item2) => ({
                ...item2,
                tabelafaixas: item2.tabelafaixas.filter((item3) => item3.valor),
              })).filter((item2) => item2.tabelafaixas.length > 0),
            }));
      setCalculoDetalhe({ usuario: dados?.cotacao?.info?.usuario, dependentes: dados?.cotacao?.dependentes, id: dados?.cotacao?.id, vidas: dados?.cotacao?.vidas, nome: dados?.cotacao?.info.descricao, cliente: dados?.cotacao?.cliente, estado: dados?.cotacao?.estado });

      setModalVisualizarOperadora(true);

      let idtabelas = []
      dados.listagem.map((item) => {
        item.tabelas.map((item) => {
          idtabelas.push({ idtabela: item.id, visualizar: true })
        })
      })
      setSelecteds((prevSelecteds) => [...prevSelecteds, ...idtabelas]);
      setSelectedsTotal((prevSelecteds) => [...prevSelecteds, ...idtabelas]);
      setCalculo(dados?.listagem.map((item) => { return { visualizar: true, ...item } }));
      handleCarregamento(false);

    }
    setLoading(false);
  };




  const [selecteds, setSelecteds] = useState([]);
  const handleMouseOver = (idtabela, id) => {
    let elemento = document.querySelector(`tr[id="${id}"]`);



    if (elemento) {
      let elemento2 = elemento.querySelector(`div[id="checkbox-${idtabela}"]`);

      if (elemento2) {
        elemento2.style.backgroundColor = "#0F4CBA";
      }

      elemento.style.backgroundColor = "#fff";
      elemento.style.border = "1px solid #0F4CBA";
      elemento.style.color = "#092140";


    }

  };
  const handleMouseOut = (idtabela, id) => {
    let elemento = document.querySelector(`tr[id="${id}"]`);
    if (elemento && !selecteds.some((item) => item.tabela === idtabela && item.calculo === id)) {
      let elemento2 = elemento.querySelector(`div[id="checkbox-${idtabela}"]`);
      if (elemento2) {
        elemento2.style.backgroundColor = "#fff";
      }
      elemento.style.backgroundColor = "#fff";
      elemento.style.borderColor = "#fff";
      elemento.style.color = "#092140";
    }

  };
  const handleClick = (idtabela, index) => {
    // console.log('handleClick-> idtabela -> ', { idtabela }, selecteds.some((item) => item.idtabela == idtabela))

    if (selecteds.find((item) => (item.idtabela == idtabela))) {
      let item = [...selecteds];
      item[index].visualizar = !item[index].visualizar
      setSelecteds(item);
    }

  };
  const [valores, setValores] = useState()
  useEffect(() => {
    let valor = []

    calculoFiltro?.filter((fill) => fill.status == 1).map((item) => {
      //  handleClickOperadoraSelect(item)

      valor.push({
        operadora: item.nome, valor: item?.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) =>
          item2?.tabelafaixas.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).filter((item3) => item3.valor).reduce((acumulador, element) => {
            return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : calculoDetalhe?.vidas))
          }, 0)).sort((a, b) => a - b)[0]
      })

      valor.push({
        operadora: item.nome, valor: item?.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) => item2.tabelafaixas.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).filter((item3) => item3.valor).reduce((acumulador, element) => {

          return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : calculoDetalhe?.vidas))

        }, 0)).sort((a, b) => a - b)[item.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) => item2.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + parseFloat(element?.valor?.replace(',', '.')) }, 0)).sort((a, b) => a - b).length - 1]
      })


    })

    if (valor?.length > 0) {
      valor = valor.sort((a, b) => a.valor - b.valor)
      let valorMaior = valor[valor.length - 1]?.valor
      let operadoraMaior = valor[valor.length - 1]?.operadora
      let valorMenor = valor[0]?.valor
      let operadoraMenor = valor[0]?.operadora
      let valorIntermediario = valor[Math.floor(valor.length / 2)]?.valor
      let operadoraIntermediario = valor[Math.floor(valor.length / 2)]?.operadora
      setValores({ valorMaior, operadoraMaior, valorMenor, operadoraMenor, valorIntermediario, operadoraIntermediario })
    }
  }, [calculoFiltro])

  const handleReCalcularValores = () => {
    let valor = []

    calculoFiltro?.filter((fill) => fill.status == 1).map((item) => {
      //  handleClickOperadoraSelect(item)

      valor.push({
        operadora: item.nome,
        valor: item?.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) =>
          item2?.tabelafaixas.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).filter((item3) => item3.valor).reduce((acumulador, element) => {
            return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : calculoDetalhe?.vidas))
          }, 0)).sort((a, b) => a - b)[0]
      })

      valor.push({
        operadora: item.nome, valor: item?.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) => item2.tabelafaixas.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).filter((item3) => item3.valor).reduce((acumulador, element) => {

          return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : calculoDetalhe?.vidas))

        }, 0)).sort((a, b) => a - b)[item.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) => item2.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + parseFloat(element?.valor?.replace(',', '.')) }, 0)).sort((a, b) => a - b).length - 1]
      })

    })
    valor = valor.filter((v) => v.valor)

    if (valor?.length > 0) {
      valor = valor.sort((a, b) => a.valor - b.valor)
      let valorMaior = valor[valor.length - 1]?.valor
      let operadoraMaior = valor[valor.length - 1]?.operadora
      let valorMenor = valor[0]?.valor
      let operadoraMenor = valor[0]?.operadora
      let valorIntermediario = valor[Math.floor(valor.length / 2)]?.valor
      let operadoraIntermediario = valor[Math.floor(valor.length / 2)]?.operadora
      setValores({ valorMaior, operadoraMaior, valorMenor, operadoraMenor, valorIntermediario, operadoraIntermediario })
    } else {
      setValores({ valorMaior: 0, operadoraMaior: '', valorMenor: 0, operadoraMenor: '', valorIntermediario: 0, operadoraIntermediario: '' })
    }

  }
  const handleClickOperadoraSelectAll = async () => {
    setLoadingDetalheSelecionado(true);
    let dados = [...selecteds]
    calculoFiltro.map((item) => {

      item.tabelas.map((info) => {
        if (!dados.some((item) => item.idtabela == info.id)) {
          dados.push({ idtabela: info.id, visualizar: true })
        }
      })

      //  await handleOperadora(item.id, { ids: item.tabelas.map((item2) => { return item2.id }) }, false);
    })
    setSelecteds(dados)
    setLoadingDetalheSelecionado(false);
  };
  const handleClickOperadoraDeselectAll = async () => {
    setLoadingDetalheSelecionado(true);
    let item5 = [...selecteds];

    calculoFiltro.map((item) => {
      // console.log('antes', item5)
      item.tabelas.map((item2) => {
        if (item5.some((item4) => item4.idtabela == item2.id)) {
          item5 = item5.filter((item1) => item1.idtabela !== item2.id);
        }
      })
      // console.log('dps', item5)
      //gerarTabelaSelecionada(item5, false)

    })
    setSelecteds(item5);
    setLoadingDetalheSelecionado(false);

  };
  const handleClickOperadoraSelect = async (item) => {
    setLoadingDetalheSelecionado(true);
    let dados = [...selecteds]
    console.log({ item })
    item.tabelas.map((info) => {
      dados.push({ visualizar: true, idtabela: info.id })
    })
    setSelecteds(dados)
    //  await handleOperadora(item.id, { ids: item.tabelas.map((item2) => { return item2.id }) }, false);

  };
  useEffect(() => {
    handleReCalcularValores()
  }, [selecteds])
  const handleClickOperadoraDeselect = async (item) => {
    setLoadingDetalheSelecionado(true);
    let item5 = [...selecteds];
    // console.log('antes', item5)
    item.tabelas.map((item2) => {
      item5 = item5.filter((item1) => item1.idtabela !== item2.id);
    })
    // console.log('dps', item5)
    //gerarTabelaSelecionada(item5, false)
    setSelecteds(item5);
    setLoadingDetalheSelecionado(false);

  };
  const handleVisualizarCotacoes = () => {
    gerarTabelaSelecionada(calculoFiltro, false);
  };
  const handleVisualizarCotacoesTodas = () => {
    gerarTabelaSelecionada(calculoFiltro, true);
  };

  const traducaoCobertura = [{ value: 'Hospitalar', label: 'Hospitalar' },
  { value: 'Urgência + Emergência', label: 'Urgência e Emergência' },
  { value: 'Ambulatorial', label: 'Ambulatorial' },
  { value: 'Completo (AMB + HOSP + OBST)', label: 'Amb + Hosp Com Obstetrícia' },
  { value: 'Completo (AMB + HOSP + OBST) + Odontológico', label: 'Amb + Hosp + Odonto Com Obstetrícia' },
  { value: 'Hospitalar + Odontológico', label: 'Hospitalar + Odontológico' },
  { value: 'Ambulatorial + Odontológico', label: 'Ambulatorial Com Odonto' },
  { value: 'Hospitalar + Obstetrícia', label: 'Hospitalar + Obstetrícia' },
  { value: 'Ambulatorial + Hospitalar', label: 'Amb + Hosp Sem Obstetrícia' },
  { value: 'Amb + Hosp + Odonto sem Obstetrícia', label: 'Amb + Hosp + Odonto sem Obstetrícia' }]
  const traducaoCoparticipacao = [{ value: 'Sim', label: 'Com Coparticipação' }, { value: 'Não', label: 'Sem Coparticipação' }, { value: 'Parcial', label: 'Com Coparticipação Parcial' }]
  const traducaoContratacao = [{ value: 'OPCIONAL', label: 'Opcional' }, { value: 'COMPULSÓRIO', label: 'Compulsório' }]
  const traducaoReembolso = [{ value: 'Sim', label: 'Com Reembolso' }, { value: 'Não', label: 'Sem Reembolso' }]

  const [municipio, setMunicipio] = useState()
  const [cobertura, setCobertura] = useState()
  const [coparticipacao, setCoparticipacao] = useState()
  const [acomodacao, setAcomodacao] = useState()
  const [contratacao, setContratacao] = useState()
  const [reembolso, setReembolso] = useState()


  const [municipioList, setMunicipioList] = useState([])
  const [coberturaList, setCoberturaList] = useState([])
  const [coparticipacaoList, setCoparticipacaoList] = useState([])
  const [acomodacaoList, setAcomodacaoList] = useState([])
  const [contratacaoList, setContratacaoList] = useState([])
  const [reembolsoList, setReembolsoList] = useState([])

  const handleFiltro = () => {

    let filtro = [...calculo]
    let municipiodArray = []
    let coberturasarray = []
    let contratacaoarray = []
    let acomodacaoArray = []
    let coparticipacoesarray = []
    let reembolsoArray = []
    filtro = filtro.filter((fill) => fill.status == 1)
    filtro = filtro.map((fill) => { return { ...fill, tabelas: fill?.tabelas.filter((fill2) => (acomodacao?.value ? (fill2.acomodacao == acomodacao?.value ? fill2 : false) : fill2)) } })

    filtro = filtro.map((fill) => {
      return {
        ...fill, tabelas: fill?.tabelas.filter((fill2) => (contratacao?.value ? (
          (fill2.contratacao == 'AMBOS' || contratacao?.value == 'AMBOS') ? true : fill2.contratacao == contratacao?.value
        ) : fill2))
      }
    })

    filtro = filtro.map((fill) => { return { ...fill, tabelas: fill?.tabelas.filter((fill2) => (reembolso?.value ? (fill2.possuereembolso == reembolso?.value ? fill2 : false) : fill2)) } })

    filtro = filtro.map((fill) => { return { ...fill, tabelas: fill?.tabelas.filter((fill2) => (cobertura?.value ? (fill2.cobertura == cobertura?.value ? fill2 : false) : fill2)) } })

    filtro = filtro.map((fill) => { return { ...fill, tabelas: fill?.tabelas.filter((fill2) => ((coparticipacao?.value) ? (['Sim', 'Total'].includes(fill2.coparticipacao) ? "Sim" : fill2.coparticipacao) == coparticipacao?.value : fill2)) } })

    filtro = filtro.map((fill) => { return { ...fill, tabelas: fill?.tabelas.filter((fill2) => (municipio?.value ? fill2.areacomercializacaoestadosmunicipios.some((item) => item.id == municipio?.value) : fill2)) } })

    filtro = filtro.filter((fill) => fill.tabelas?.length > 0 ? true : false)
    calculo.filter((fill) => fill.status == 1).map((fill) => {
      fill.tabelas.map((item) => {
        item.areacomercializacaoestadosmunicipios?.map((item2) => {
          if (!municipiodArray.some((item3) => item3.id == item2?.id)) {
            municipiodArray.push({ id: item2?.id, nome: item2?.nome })
          }
        })
        if (item.possuereembolso && !reembolsoArray.includes(item.possuereembolso)) {
          reembolsoArray.push(item.possuereembolso)
        }
        if (item.acomodacao && !acomodacaoArray.includes(item.acomodacao)) {
          acomodacaoArray.push(item.acomodacao)
        }
        if (item.cobertura && !coberturasarray.includes(item.cobertura)) {
          coberturasarray.push(item.cobertura)
        }
        if (item.coparticipacao && !coparticipacoesarray.includes(item.coparticipacao)) {
          if ((item.coparticipacao == 'Sim' || item.coparticipacao == 'Total')) {
            if (!coparticipacoesarray.some((item) => (item == 'Sim' || item == 'Total'))) {
              coparticipacoesarray.push(item.coparticipacao)
            }
          } else {
            coparticipacoesarray.push(item.coparticipacao)
          }
        }
        if (item.contratacao && !contratacaoarray.includes(item.contratacao)) {
          contratacaoarray.push(item.contratacao)
        }
      })
    })

    setCalculoFiltro(filtro)
    if (municipioList?.length == 0) {
      setMunicipioList(municipiodArray)
    }
    if (coberturaList?.length == 0) {
      setCoberturaList(coberturasarray)
    }
    if (coparticipacaoList?.length == 0) {
      setCoparticipacaoList(coparticipacoesarray)
    }
    if (acomodacaoList?.length == 0) {
      setAcomodacaoList(acomodacaoArray)
    }
    if (contratacaoList?.length == 0) {
      setContratacaoList(contratacaoarray)
    }
    if (reembolsoList?.length == 0) {
      setReembolsoList(reembolsoArray)
    }

  }
  useEffect(() => {
    if (municipio || cobertura || coparticipacao || acomodacao || contratacao || reembolso) {
      handleFiltro()
    }
  }, [municipio, cobertura, coparticipacao, acomodacao, contratacao, reembolso, calculo])
  useEffect(() => {
    handleFiltro()
  }, [calculo])
  return (
    <section className="about-two ">
      <div className="container" >
        <div className="" style={{ width: "100%", marginTop: '20px' }}>
          {(loading) && (
            <div
              className={`col-lg-12 col-sm-12 item`}
              style={{
                height: "20vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                width: "100%",
                zIndex: 99999999999999,
              }}
            >
              <img src={Icone} className="rotating-image" />
            </div>
          )}
          {(!loading) && (
            <div style={{ marginBottom: 20 }} >
              {calculo?.length > 0 ? <>


                <Visualizar handleOperadora={handleOperadora} calculoDetalhe={calculoDetalhe} calculo={calculo} calculoFiltro={calculoFiltro} setModalVisualizarOperadora={setModalVisualizarOperadora} handleVisualizarCotacoes={handleVisualizarCotacoes} ramo={ramo} handleClickOperadoraSelect={handleClickOperadoraSelect} handleClickOperadoraDeselect={handleClickOperadoraDeselect} loadingDetalhes={loadingDetalhes} valores={valores} infoDependente={calculoDetalhe?.dependentes} setModalVisualizarDetalheSelecionadoTotal={setModalVisualizarDetalheSelecionadoTotal} handleVisualizarCotacoesTodas={handleVisualizarCotacoesTodas} municipios={municipios} setCalculoFiltro={setCalculoFiltro} handleClickOperadoraSelectAll={handleClickOperadoraSelectAll} handleClickOperadoraDeselectAll={handleClickOperadoraDeselectAll} setInfo={setInfo} traducaoCobertura={traducaoCobertura} traducaoCoparticipacao={traducaoCoparticipacao} traducaoContratacao={traducaoContratacao} traducaoReembolso={traducaoReembolso} coberturaList={coberturaList} municipioList={municipioList} coparticipacaoList={coparticipacaoList} acomodacaoList={acomodacaoList} contratacaoList={contratacaoList} reembolsoList={reembolsoList} setMunicipio={setMunicipio} setCobertura={setCobertura} setCoparticipacao={setCoparticipacao} setAcomodacao={setAcomodacao} setContratacao={setContratacao} setReembolso={setReembolso}
                  cobertura={cobertura} municipio={municipio} coparticipacao={coparticipacao} acomodacao={acomodacao} reembolso={reembolso} contratacao={contratacao} modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} visualizar={visualizar} setVisualizar={setVisualizar} />

                {/* <VisualizarDetalhe handleCarregamento={handleCarregamento} handleClick={handleClick} handleMouseOut={handleMouseOut} handleMouseOver={handleMouseOver} selecteds={selecteds}
                  setModalVisualizarDetalhe={setModalVisualizarDetalhe} infoDependente={calculoDetalhe?.dependentes} faixas={faixas} info={info} modalVisualizarDetalhe={modalVisualizarDetalhe} calculoDetalhe={calculoDetalhe} handleVisualizarCotacoes={handleVisualizarCotacoes} loadingDetalhes={loadingDetalhes} setInfo={setInfo} id={id} setVisualizar={setVisualizar} handleClickOperadoraDeselectAll={handleClickOperadoraDeselectAll} handleClickOperadoraSelectAll={handleClickOperadoraSelectAll} /> */}

                <VisualizarSelecionado id={id} handleCarregamento={handleCarregamento} selecteds={selecteds} setModalVisualizarDetalhe={setModalVisualizarDetalheSelecionado} infoDependente={calculoDetalhe?.dependentes} faixas={faixas} info={info} modalVisualizarDetalhe={modalVisualizarDetalheSelecionado} calculoDetalhe={calculoDetalhe} handleClick={handleClick}
                  gerarTabelaSelecionada={gerarTabelaSelecionada} loadingDetalhes={loadingDetalhes} setLoadingDetalhe={setLoadingDetalhe} setInfo={setInfo} setVisualizar={setVisualizar} handleClickOperadoraDeselectAll={handleClickOperadoraDeselectAll} handleClickOperadoraSelectAll={handleClickOperadoraSelectAll} calculoFiltro={calculoFiltro} />


                <VisualizarSelecionadoTodos id={id} handleCarregamento={handleCarregamento} selectedsTotal={selectedsTotal} setModalVisualizarDetalhe={setModalVisualizarDetalheSelecionadoTotal} infoDependente={calculoDetalhe?.dependentes} faixas={faixas} info={info} modalVisualizarDetalhe={modalVisualizarDetalheSelecionadoTotal} calculoDetalhe={calculoDetalhe} handleClick={handleClick}
                  gerarTabelaSelecionada={gerarTabelaSelecionada} loadingDetalhes={loadingDetalhes} setLoadingDetalhe={setLoadingDetalhe} setInfo={setInfo} setVisualizar={setVisualizar} handleClickOperadoraDeselectAll={handleClickOperadoraDeselectAll} handleClickOperadoraSelectAll={handleClickOperadoraSelectAll} calculoFiltro={calculoFiltro}  />

              </> :
                <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  Nenhum cálculo encontrado
                </div>
              }
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default CotacoesPrecos;
