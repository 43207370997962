import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import Icone from "../../assets/icones/icone.png"
import { jsPDF } from "jspdf";
import { Api } from "../../api/apiCotacoes";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useCookies } from 'react-cookie';
import { Header } from "./header";
import { Footer } from "./footer";
import { Filter } from "./filter";
import { baseUrlApi } from "../../api/baseUrl";


export const Produtos = ({ info, modalVisualizarDetalhe, infoDependente, calculoDetalhe, selecteds, handleClick, loadingDetalhes, setInfo, id, cotacao, Compartilha, setVisualizar, handleClickOperadoraDeselectAll, handleClickOperadoraSelectAll, exibirTodos,calculoFiltro }) => {
  const divRef = useRef(null);
  const [display, setDisplay] = useState('block')
  const [viewCorretor, setViewCorretor] = useState(true)
  const [cookie] = useCookies(['authUser'])
  const obj = cookie.authUser

  useEffect(() => {
    setDisplay('block')
  }, [modalVisualizarDetalhe])


  const [municipios, setMunicipios] = useState([])
  const [modalMunicipios, setModalMunicipios] = useState(false)
  const handleMunicipios = (municipio) => {
    setMunicipios(municipio)
    setModalMunicipios(true)
  }
  const handleVisualizar = (index, status) => {
    let dados = [...info]
    dados[index].visualizar = status
    setInfo(dados)
  }
  const [viewTotal, setViewTotal] = useState(true)
  const handleViewTotal = () => {

    info.map((operadora) => {
      operadora.visualizar = !viewTotal
    })
    setViewTotal(!viewTotal)
  }

  return (
    <div >
      <div className="" style={{ width: '100%', overflowX: 'auto' }}>

        {
          <>

            <Filter Compartilha={Compartilha} cotacao={true} divRef={divRef} handleViewTotal={handleViewTotal} setDisplay={setDisplay} viewTotal={viewTotal} setViewCorretor={setViewCorretor} viewCorretor={viewCorretor} />
            <div className="modal-body" id='minha-div' ref={divRef} >
              <table className="table" style={{ marginTop: 0 }}  >

                <tbody id='tabela' style={{ display: "flex", paddingRight: 30, paddingLeft: 30, flexDirection: "column", marginTop: 0 }}>
                  <Header calculoDetalhe={calculoDetalhe} index={0} cotacao={cotacao} viewTotal={viewTotal} handleViewTotal={handleViewTotal} setDisplay={setDisplay} divRef={divRef} viewCorretor={viewCorretor} />
                  {exibirTodos &&
                    <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center", marginLeft: 0 }}>
                      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row', paddingLeft: 60 }}>
                        <input type="checkbox" defaultChecked={true} className="form-control" onChange={(e) => {
                          if (e.target.checked) {
                            setVisualizar(calculoFiltro.map(() => { return true }))
                            handleClickOperadoraSelectAll()
                          } else {
                            setVisualizar(calculoFiltro.map(() => { return false }))
                            handleClickOperadoraDeselectAll()
                          }
                        }} />
                        <label style={{ marginBottom: 10, textAlign: 'center', marginLeft: 5 }} >Exibir Todas</label>
                      </div>
                    </div>
                  }

                  {info?.length > 0 && info.filter((fill) => selecteds.length > 0 ? selecteds.some((select) => fill.tabelas.produto.some((som) => som.id == select.idtabela)) : true)?.map((operadora, index) => (
                    <>
                      <div style={index == 0 ? { breakInside: '' } : { pageBreakAfter: 'always' }}>
                        <div>
                          {selecteds.some((select) => operadora?.tabelas.produto.some((som) => som.id == select.idtabela)) &&
                            (
                              <div onClick={() => { handleVisualizar(index, !operadora.visualizar) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: operadora.visualizar ? 0 : 10, boxShadow: '0 0 0 0.5px #ccc', borderRadius: 6, ...(index != 0 && { pageBreakBefore: 'always' }) }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                  <img src={`${baseUrlApi}/imagens/${operadora?.imagem}`} width='200px' style={{ margin: 'auto', marginRight: 10 }} />
                                </div>
                                <div style={{ position: "absolute", right: '40px', display }} >
                                  {operadora.visualizar ?
                                    <IoIosArrowDown color="#092140" onClick={() => { handleVisualizar(index, true) }} />
                                    :
                                    <IoIosArrowForward color="#092140" onClick={() => { handleVisualizar(index, false) }} />
                                  }
                                </div>
                              </div>
                            )
                          }
                          {(display == 'none' ? true : operadora.visualizar) && operadora.tabelas.produto.map((item, indexProduto) => (
                            <>
                              {(selecteds.some((select) => select.idtabela == item?.id)) &&
                                (<tr key={'tr-' + item.id} className="tr" id={item?.faixas.map((item3) => { return item3.id }).join('&')} style={{ flexDirection: 'column', marginBottom: 10, display: display == 'none' ? selecteds.find((select) => item.id == select.idtabela)?.visualizar ? 'flex' : 'none' : 'flex' }} >

                                  <tr style={{ textAlign: 'center' }}  >
                                    <button style={{ position: 'absolute', left: 30, color: '#D75350', display: display }} className='btn btn-default'  >
                                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flex: 1, paddingTop: '20px', paddingLeft: 50 }} >
                                        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row' }}>
                                          <input type="checkbox" checked={selecteds.find((select) => item.id == select.idtabela)?.visualizar} className="form-control" onChange={(e) => {
                                            let find = [...selecteds]
                                            let indexPrd = selecteds.findIndex((select) => item.id == select.idtabela)
                                            if (e.target.checked) {
                                              find[indexPrd].visualizar = false
                                            } else {
                                              find[indexPrd].visualizar = true
                                            }
                                            handleClick(item.id, indexPrd)
                                          }}
                                          />
                                          <label style={{ marginBottom: 10, marginLeft: 5 }}>Exibir</label>
                                        </div>
                                      </div>
                                    </button>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 80, backgroundColor: 'rgb(204, 204, 204,0.5)', borderRadius: 6 }}>

                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'center', width: '100%', marginBottom: 0, lineHeight: 1.2 }} >
                                          {operadora.operadora?.toUpperCase()} <br /> {item.nome}<br /> {item.cobertura ? `${item.cobertura}` : ''}  {item.vidas} {item.acomodacao ? `- ${item.acomodacao} - ` : ''} {(item.coparticipacao == 'Sim' || item.coparticipacao == "Total" || item.coparticipacao == "Parcial") ? `Com coparticipacão ${item.coparticipacao == "Total" || item.coparticipacao == "Parcial" ? `(${item.coparticipacao})` : ""} ${item.percentual ? 'de  ' + item.percentual + '%' : ''}` : 'Sem coparticipacão'}</p>
                                      </div>
                                    </div>
                                    <table className="" style={{ width: '100%' }}  >
                                      <tbody  >
                                        <tr style={{ padding: 1, height: 40, display: 'flex', justifyContent: 'space-around', border: 'none' }}>
                                          {item?.faixas.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).sort((a, b) => a.idfaixa - b.idfaixa)?.map((item, index) => (
                                            <div key={item.id} style={{ textAlign: 'center', borderWidth: 1, padding: 5, border: 0, width: 80 }} >
                                              <p style={{ fontWeight: 'bold', fontSize: 15, height: 6, lineHeight: 1 }} > {item.faixa?.nome ? item.faixa.nome?.replace('-', ' à ') + "" : ''} <br /> {parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor.replace(',', '.') : (infoDependente?.length > 0) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1)} vida(s)</p>
                                            </div>
                                          ))}
                                          <div style={{ textAlign: 'center', borderWidth: 1, padding: 5, lineHeight: 1, width: 80 }} >
                                            <p style={{ fontWeight: 'bold', fontSize: 15, height: 8 }} >Total  <br />  {calculoDetalhe?.vidas > 0 ? calculoDetalhe.vidas : 1} vida(s)</p>
                                          </div>
                                        </tr>
                                      </tbody>
                                    </table>

                                  </tr>
                                  {(item.faixas?.length > 0 || item.precovida) &&
                                    <tr style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}   >
                                      <td style={{ textAlign: 'center', padding: 5, width: '100%' }} >
                                        <table className="table" style={{ cursor: 'pointer' }}>
                                          <tbody>
                                            {item.status == 2 ?
                                              <tr style={{ textAlign: 'center', borderWidth: 1 }}>
                                                Em atualização
                                              </tr>
                                              :
                                              <>
                                                {(item.faixas?.length > 0 || item.precovida) &&
                                                  <tr >

                                                    {item.faixas?.length ?
                                                      <>
                                                        {item?.faixas?.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true)?.sort((a, b) => a.idfaixa - b.idfaixa)?.map((item2) => (
                                                          <>

                                                            {item2.status ?
                                                              <td style={{ textAlign: 'center', border: 0, color: '#092140', padding: 5, flex: 1, width: 95 }} >
                                                                <p style={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1, height: 8, color: '#092140' }} >
                                                                  {(
                                                                    ((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * ((item2.statuscoeficiente && item2.coeficiente && item2.coeficiente != 0) ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : 0))

                                                                    * parseFloat(item2?.cotacoesfaixa?.valor ? item2?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente?.length > 0) ? infoDependente?.map((elemento) => (elemento <= item2?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                                                  <span style={{ fontSize: 12 }} >

                                                                    <br />{((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * ((item2.statuscoeficiente && item2.coeficiente && item2.coeficiente != 0) ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : '0'))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br /> p/vida</span>
                                                                </p>

                                                              </td>
                                                              :
                                                              <td style={{ textAlign: 'center', border: 0, flex: 1, width: 95 }} >
                                                                <p style={{ fontWeight: 'bold', color: 'red', fontSize: 15, height: 8 }} >Suspenso</p>

                                                              </td>}
                                                          </>

                                                        ))}
                                                        <td style={{ textAlign: 'center', border: 0, color: '#092140', padding: 5, flex: 1, width: 95, lineHeight: 1 }} >
                                                          <p style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center', marginBottom: 0 }} className='valorTotal' >

                                                            {Number(item?.faixas?.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true)?.reduce((acumulador, item) => {
                                                              return acumulador + ((item.status ? ((parseFloat(item.valor ? item.valor?.replace(',', '.') : 0) * ((item?.statuscoeficiente && item?.coeficiente && item.coeficiente != 0) ? parseFloat(item?.coeficiente / 100) : 1)) + parseFloat(item.odonto ? item.odonto?.replace(',', '.') : '0')) : 0)
                                                                * parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente?.length > 0) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : calculoDetalhe?.vidas))
                                                            }, 0))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                          </p>
                                                          <span style={{ fontSize: 12 }} >Valor Total </span>
                                                        </td>
                                                      </>
                                                      :
                                                      <>
                                                        {item.precovida &&
                                                          <>
                                                            <td style={{ textAlign: 'center', borderWidth: 1, padding: 5, flex: 1 }} >
                                                              <p style={{ fontSize: 12 }} >R$ {item.precovida} p/vida</p>
                                                            </td>
                                                            <td style={{ textAlign: 'center', borderWidth: 1, padding: 5, flex: 1 }} >
                                                              <p style={{ fontWeight: 'bold', fontSize: 15 }} className='valorTotal' >{(parseFloat(item.precovida.replace(',', '.')) * parseFloat(calculoDetalhe.vidas))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                            </td>
                                                          </>

                                                        }

                                                      </>
                                                    }


                                                  </tr>
                                                }
                                              </>

                                            }

                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  }


                                </tr>
                                )
                              }

                            </>
                          ))}
                        </div>

                        <Footer visualizar={operadora.visualizar} tabelas={operadora.tabelas} municipio={municipios} operadora={operadora.operadora} />
                      </div>





                      {/* {operadora.visualizar && operadora.tabelas?.observacoes?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 16, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Observações Importantes</p>
                            <p style={{ fontSize: 14, color: 'red', textAlign: 'center', lineHeight: 1.3 }}>"Esta simulação poderá variar de acordo com o perfil do cliente e a critério da operadora.<br /> Preços, condições e critérios de aceitação da proposta estão sujeitos a analise e confirmação no momento da implantação do contrato."</p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.observacoes?.map((item, index) => (
                                  <>
                                    <p>
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>
                                    <p style={{ fontSize: 16, marginBottom: 10 }}>
                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.item.obs
                                            ?.replace(/font-family:[^;]*;/g, '') // Remove font-family
                                            ?.replace(/background-color:[^;]*;/g, '') // Remove background-color
                                            ?.replace(/font-size:[^;]*;/g, '') // Remove font-size
                                        }}
                                      ></p>
                                    </p>

                                  </>
                                ))}
                              </div>
                            </Col>
                          </div>
                        </div>
                      }

                      {operadora.visualizar && operadora.tabelas?.rede?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Rede de Atendimento</p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.rede?.map((item) => (
                                  <>
                                    <p>
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>
                                    <p style={{ fontSize: 16, marginBottom: 10 }}>
                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></p>
                                    </p>
                                  </>
                                ))}
                              </div>
                            </Col>
                          </div>
                        </div>
                      }

                      {operadora.visualizar && operadora.tabelas?.reembolso?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Diferenciais e Reembolsos</p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.reembolso?.map((item) => (
                                  <>
                                    <p>
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>
                                    <p style={{ fontSize: 16, marginBottom: 10 }}>
                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></p>
                                    </p>
                                  </>
                                ))}
                              </div>
                            </Col>
                          </div>
                        </div>
                      }
                      {operadora.visualizar && operadora.tabelas?.carencia?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Carências</p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: "none", borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.carencia?.map((item) => (
                                  <>
                                    <p>
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>
                                    <p style={{ fontSize: 16, marginBottom: 10 }}>
                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></p>
                                    </p>
                                  </>

                                ))}

                              </div>
                            </Col>
                          </div>
                        </div>
                      }

                      {operadora.visualizar && operadora.tabelas?.dependente?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Dependentes</p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}   >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.dependente?.map((item) => (
                                  <>
                                    <p>
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>
                                    <p style={{ fontSize: 16, marginBottom: 10 }}>

                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></p>
                                    </p>
                                  </>

                                ))}

                              </div>
                            </Col>
                          </div>
                        </div>
                      }


                      {operadora.visualizar && operadora.tabelas?.documento?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Documentos Necessários</p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.documento?.map((item) => (
                                  <>
                                    <p >
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>
                                    <p style={{ fontSize: 16, marginBottom: 10 }}>
                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></p>
                                    </p>
                                  </>


                                ))}

                              </div>
                            </Col>
                          </div>
                        </div>
                      }

                      {operadora.visualizar && operadora.tabelas?.pagamento?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Formas de Pagamentos</p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.pagamento?.map((item) => (
                                  <>
                                    <p >
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>
                                    <p style={{ fontSize: 16, marginBottom: 10 }}>
                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></p>
                                    </p>
                                  </>

                                ))}

                              </div>
                            </Col>
                          </div>
                        </div>
                      }

                      {operadora.visualizar && operadora.tabelas?.entidades?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Entidades e Profissões  </p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.entidades?.map((item) => (
                                  <div key={`entidades-${item.id}`} style={{ marginBottom: 20 }}>
                                    <p style={{ fontWeight: 'bold' }} >
                                      <p>
                                        <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                        <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                      </p>
                                    </p>
                                    <p key={item.id} style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 12 }}>
                                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 16 }} >{item.item?.entidade?.nome}:</p>
                                      {item.item?.profisso?.nome} - </p>
                                  </div>
                                ))}
                              </div>
                            </Col>
                          </div>
                        </div>
                      }
                      {operadora.visualizar && operadora.tabelas?.areacomercializacao?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                          <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Áreas de Comercialização  </p>
                            <Col lg={12}>
                              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                                {operadora.tabelas && operadora.tabelas?.areacomercializacao?.map((item) => (
                                  <div key={item} style={{ marginBottom: 20 }} >
                                    <p>
                                      <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                      <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                    </p>


                                    {item?.municipios.length > 0 && item?.municipios?.filter((item, index, self) => index === self?.findIndex((m) => m.idestado === item.idestado)).map((muni) => (
                                      <div style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 16 }}>

                                        {!operadora.tabelas?.areacomercializacao[0]?.descricoes?.some((element) => (Number(element?.idestado) == muni.idestado && element.descricao)) ?

                                          <>
                                            {item?.municipios.map((item3, index) => (
                                              <>
                                                <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3?.nome)} -` }}  ></span>
                                              </>
                                            ))}
                                          </>
                                          :
                                          <>
                                            <span key={`Comercializacao-`}  >{info?.areacomercializacao && info?.areacomercializacao[0]?.descricoes.find((element) => Number(element.idestado) == muni.idestado)?.descricao}</span><br />
                                            <a style={{ textDecoration: 'underline', textAlign: 'justify' }} onClick={() => { handleMunicipios(item?.municipios) }} > Ver municípios</a>
                                          </>
                                        }
                                      </div>
                                    ))

                                    }
                                    <br />
                                    <p  >{item.descricao}</p>

                                  </div>

                                ))}

                              </div>
                            </Col>
                          </div>
                        </div>
                      } */}
                    </>
                  )
                  )}
                </tbody>
              </table>
            </div >
          </>}
      </div>
      <Modal isOpen={modalMunicipios} centered={true}>
        <div className="modal-header" style={{ border: 'none' }}>

          <button
            type="button"
            onClick={() => {
              setModalMunicipios(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <p style={{ color: '#092140', textAlign: 'center' }}>Municípios</p>

          <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
            {municipios.map((item3, index) => (
              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.municipio.nome)} -` }}  ></span>
            ))}
          </Row>
        </div>

      </Modal>
    </div >
  )
};
