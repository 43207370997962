import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { ExitModal } from "../../components/exitModal";
import { Produtos } from "../../../components/produtos";

export const VisualizarSelecionadoTodos = ({ faixas, info, modalVisualizarDetalhe, setModalVisualizarDetalhe, infoDependente, calculoDetalhe, selectedsTotal, handleClick, gerarTabelaSelecionada, handleCarregamento, loadingDetalhes, setLoadingDetalhe, setInfo, id, setVisualizar, handleClickOperadoraDeselectAll, handleClickOperadoraSelectAll,calculoFiltro }) => {
  return (
    <Modal id="modal-center3" size="xl" isOpen={modalVisualizarDetalhe} centered={true} style={{ maxWidth: 1200 }}>
      <div className="" style={{ width: '100%', overflowX: 'auto' }}>
        <Row>
          <ExitModal setModal={setModalVisualizarDetalhe} />
        </Row>
        <Produtos info={info} infoDependente={infoDependente} calculoDetalhe={calculoDetalhe} selecteds={selectedsTotal} handleClick={handleClick} handleCarregamento={handleCarregamento} loadingDetalhes={loadingDetalhes} setInfo={setInfo} id={id} cotacao={true} setVisualizar={setVisualizar} handleClickOperadoraDeselectAll={handleClickOperadoraDeselectAll} handleClickOperadoraSelectAll={handleClickOperadoraSelectAll} exibirTodos={true} calculoFiltro={calculoFiltro} />
      </div>
    </Modal >
  )
};
